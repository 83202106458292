import axios from '@/apis/axios';
import { getAccessToken } from '@/lib/api';

/**
 * お客様画像取得
 */
export const getInsuredUpload = async (contractId, insuredUploadId) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(
      `/agencies/contracts/${contractId}/insured_uploads/${insuredUploadId}`,
      {
        responseType: 'arraybuffer',
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * お客様画像URL取得
 */
export const getInsuredUploadUrl = async (contractId, insuredUploadId) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(
      `/agencies/contracts/${contractId}/insured_uploads/${insuredUploadId}/url`,
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};
