<template>
  <Dialog ref="dialog" :title="$t('label.reflectContractInfo')" :color="'next'">
    <v-container>
      <v-form lazy-validation ref="form" @submit.prevent>
        <v-row>
          <div class="text-center" style="margin: 0 auto;">
            <p>{{ $t('description.reflectContractInfo.title') }}</p>
            <p>{{ $t('description.reflectContractInfo.subTitle') }}</p>
            <br />
            <p>
              {{ $t('description.reflectContractInfo.changeBefore')
              }}{{ currentValue }}
            </p>
            <p>
              {{ $t('description.reflectContractInfo.afterChange')
              }}{{ changeValue }}
            </p>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <template v-slot:footer>
      <v-btn width="33%" class="back" dark @click="close()">{{
        $t('button.no')
      }}</v-btn>
      <v-btn width="33%" class="next" dark @click="onClickConfirmButton()">
        {{ $t('button.yes') }}
      </v-btn>
    </template>

    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';

export default {
  components: { Dialog, ErrorDialog },
  data() {
    return {
      condition: [],
      currentValue: null,
      changeValue: null,
      itemName: null,
    };
  },
  mounted() {},
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    init(currentValue, contract, itemName) {
      this.$set(this, 'condition', []);
      if (itemName == this.$t('header.baseInfoTable.agencyName')) {
        // 反映対象が代理店名の場合
        this.$set(this, 'changeValue', contract.agencyNameContract);
        this.condition.agencyName = contract.agencyNameContract;
      } else if (itemName == this.$t('header.baseInfoTable.chargePersonName')) {
        // 反映対象が担当者名の場合
        this.$set(this, 'changeValue', contract.chargePersonNameContract);
        this.condition.chargePersonName = contract.chargePersonNameContract;
      }
      this.$set(this, 'currentValue', currentValue);
      this.$set(this, 'itemName', itemName);
    },
    onClickConfirmButton() {
      // 編集項目の保持
      const condition = {
        ...(this.condition.agencyName && {
          agencyName: this.condition.agencyName,
        }),
        ...(this.condition.chargePersonName && {
          chargePersonName: this.condition.chargePersonName,
        }),
      };
      if (!Object.keys(condition).length) {
        this.onErrorReflect();
      } else {
        this.$emit('onSuccess', condition);
      }
    },
    onErrorReflect() {
      this.$refs.errorDialog.open(
        this.$t('label.reflectContractInfo'),
        this.$t('error.reflectedError')
      );
    },
  },
};
</script>
