var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: { title: _vm.$t("label.sendingConfirm"), color: "next" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  staticStyle: { "font-size": "15px !important" },
                  attrs: { width: "30vw", "max-width": "170px", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("button.cancel")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "next",
                  staticStyle: { "font-size": "15px !important" },
                  attrs: {
                    width: "30vw",
                    "max-width": "170px",
                    dark: _vm.isSendButtonEnabled,
                    disabled: !_vm.isSendButtonEnabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.onClickSendButton()
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("button.send")) + "\n    ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "text-center",
                  staticStyle: { margin: "0 auto" }
                },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("description.sendMailAndSms.sendingConfirmText")
                      )
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "description.sendMailAndSms.sendingConfirmSubText"
                        )
                      )
                    )
                  ]),
                  _c("br"),
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("description.sendMailAndSms.fullNameKanji")
                        ) +
                        _vm._s(this.condition.fullNameKanji) +
                        "\n        "
                    )
                  ]),
                  this.condition.isSms
                    ? _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("description.sendMailAndSms.phoneNumber")
                            ) +
                            _vm._s(this.condition.phoneNumber) +
                            "\n        "
                        )
                      ])
                    : _vm._e(),
                  this.condition.isMail
                    ? _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("description.sendMailAndSms.mail")) +
                            _vm._s(this.condition.mail) +
                            "\n        "
                        )
                      ])
                    : _vm._e(),
                  this.condition.authFlg
                    ? _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("description.sendMailAndSms.auth")) +
                            _vm._s(
                              _vm.$t(
                                "label." +
                                  _vm.ReverseAuthType[this.condition.authFlg]
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        ],
        1
      ),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }