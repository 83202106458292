<template>
  <Dialog
    ref="dialog"
    :title="$t('title.agency.sendMessage')"
    :width="'40%'"
    :max-width="'40%'"
    :color="'next'"
  >
    <v-container class="px-4 mx-4" style="width:auto">
      <v-row>
        <v-col class="py-0" style="height:100%">
          <p class="py-0 mb-0 font-weight-black" style="font-size:20px;">
            {{ $t('label.smsMessageBody') }}
          </p>
        </v-col>
      </v-row>
      <v-row style="height: 100%">
        <v-col class="py-0 pl-0" style="height: 100%">
          <v-container>
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="smsMessageRecord in smsMessageRecords"
                  :key="smsMessageRecord.key"
                >
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text"
                  >
                    {{
                      $t(`header.smsMailMessageTable.${smsMessageRecord.key}`)
                    }}
                  </td>
                  <!-- 内容 -->
                  <td>
                    <v-row>
                      <v-col align-self="center">
                        <p class="mb-0" align="left" style="font-size:14px">
                          {{
                            smsMessageRecord.value
                              ? $t('description.messageContent.selected')
                              : $t('description.messageContent.unselected')
                          }}
                        </p> </v-col
                      ><v-col>
                        <!-- SMS送信本文編集ボタン -->
                        <v-btn
                          class="font-weight-black float-right"
                          align="center"
                          color="next"
                          :dark="smsMessageRecord.isEnabled"
                          :disabled="!smsMessageRecord.isEnabled"
                          @click="
                            onClickSelectAndEditSmsMailMessageBodyButton(
                              smsMessageRecord,
                              true
                            )
                          "
                          >{{ $t('button.edit') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" style="height:100%">
          <p class="py-0 mb-0 font-weight-black" style="font-size:20px;">
            {{ $t('label.mailMessageBody') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 pl-0" style="height: 100%">
          <v-container class="pb-0">
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="mailMessageRecord in mailMessageRecords"
                  :key="mailMessageRecord.key"
                >
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text"
                  >
                    {{
                      $t(`header.smsMailMessageTable.${mailMessageRecord.key}`)
                    }}
                  </td>
                  <!-- 内容 -->
                  <td>
                    <v-row>
                      <v-col align-self="center">
                        <p class="mb-0" align="left" style="font-size:14px">
                          {{
                            mailMessageRecord.value
                              ? $t('description.messageContent.selected')
                              : $t('description.messageContent.unselected')
                          }}
                        </p> </v-col
                      ><v-col>
                        <!-- メール送信本文編集ボタン -->
                        <v-btn
                          class="font-weight-black float-right"
                          align="center"
                          color="next"
                          :dark="mailMessageRecord.isEnabled"
                          :disabled="!mailMessageRecord.isEnabled"
                          @click="
                            onClickSelectAndEditSmsMailMessageBodyButton(
                              mailMessageRecord,
                              false
                            )
                          "
                          >{{ $t('button.edit') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <template v-slot:footer>
      <v-btn width="33%" class="back" dark @click="close()">{{
        $t('button.close')
      }}</v-btn>
    </template>

    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';

export default {
  components: {
    SimpleDialog,
    Dialog,
    ErrorDialog,
  },
  props: {
    smsMessageRecords: Array,
    mailMessageRecords: Array,
    notEditableFlg: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    // SMS・メールテンプレート選択
    onClickSelectAndEditSmsMailMessageBodyButton(messageRecord, isSms) {
      this.$emit('onSuccess', {
        isSms: isSms,
        message: JSON.parse(JSON.stringify(messageRecord)),
      });
    },
  },
};
</script>
<style>
.v_base_table_with_border {
  border: solid 1px;
}
.v_base_table_fill_width {
  width: 50%;
}
</style>
