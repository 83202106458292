import axios from '@/apis/axios';
import { getAccessToken } from '@/lib/api';

/**
 * 案件送信用メッセージ本文取得
 * @param {Number} contractId 契約ID
 */
export const getSendMessageBody = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/send_text_managements/${contractId}/send_message_body`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  // SMS・メール送信用本文
  const smsAndMailMessageBodyInfo = {
    // 案件ごとの送信用本文
    original: {
      proposingSmsBody: response.data.originalMessageBody.proposingSmsBody,
      concludedSmsBody: response.data.originalMessageBody.concludedSmsBody,
      concludedWithModifySmsBody:
        response.data.originalMessageBody.concludedWithModifySmsBody,
      unconcludedSmsBody: response.data.originalMessageBody.unconcludedSmsBody,
      proposingEmailBody: response.data.originalMessageBody.proposingEmailBody,
      concludedEmailBody: response.data.originalMessageBody.concludedEmailBody,
      concludedWithModifyEmailBody:
        response.data.originalMessageBody.concludedWithModifyEmailBody,
      unconcludedEmailBody:
        response.data.originalMessageBody.unconcludedEmailBody,
    },
    // テンプレート送信用本文
    template: {
      proposingSmsBody: response.data.templateMessageBody.sms.contractProposing,
      concludedSmsBody: response.data.templateMessageBody.sms.contractConcluded,
      concludedWithModifySmsBody:
        response.data.templateMessageBody.sms.contractConcludedWithModify,
      unconcludedSmsBody:
        response.data.templateMessageBody.sms.contractUnconcluded,
      proposingEmailBody:
        response.data.templateMessageBody.email.contractProposing,
      concludedEmailBody:
        response.data.templateMessageBody.email.contractConcluded,
      concludedWithModifyEmailBody:
        response.data.templateMessageBody.email.contractConcludedWithModify,
      unconcludedEmailBody:
        response.data.templateMessageBody.email.contractUnconcluded,
    },
  };
  return smsAndMailMessageBodyInfo;
};

/**
 * 案件送信用メッセージ本文更新
 */
export const updateSendMessageBody = async (contractId, messageBody) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/send_text_managements/${contractId}/send_message_body`,
      { ...messageBody },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};
