<template>
  <Dialog
    ref="dialog"
    :title="$t('title.agency.extraFileList')"
    :color="'next'"
  >
    <v-container>
      <v-data-table
        calculate-widths
        :headers="headers"
        :items="files"
        :hide-default-footer="true"
        class="elevation-1 ma-3"
        :loading="isLoading"
        :loading-text="$t('description.extraFileReading')"
        :no-data-text="$t('error.extraFileNotFound')"
        :items-per-page="-1"
      >
        <template v-slot:[`item.detail`]="{ item }" class="pa-0">
          <v-menu offset-y left>
            <template v-slot:activator="{ on }">
              <v-btn color="red" fab x-small dark v-on="on">
                <v-icon color="white">edit</v-icon>
              </v-btn>
            </template>
            <v-list class="ma-0 pa-0">
              <v-list-item v-if="extensionCheck(item.name)" @click="onClickPreview(item)">
                <v-icon>mdi-eye-outline</v-icon>
                <v-list-item-action class="mx-0" color="next">{{
                  $t('button.preview')
                }}</v-list-item-action>
              </v-list-item>
              <v-list-item @click="onClickEdit(item)">
                <v-icon>edit</v-icon>
                <v-list-item-action
                  class="mx-0"
                  color="next"
                  >{{ $t('button.edit') }}</v-list-item-action
                >
              </v-list-item>
              <v-list-item @click="onClickDelete(item)">
                <v-icon>delete</v-icon>
                <v-list-item-action
                  class="mx-0"
                  color="next"
                  >{{ $t('button.delete') }}</v-list-item-action
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.comment`]="{ item }" class="pa-0">
          <p
            v-for="(comment, index) in splitMessageLine(item.comment)"
            :key="index"
            class="ma-0 pa-0"
            align="left"
          >
            {{ comment }}
          </p>
        </template>
      </v-data-table>
    </v-container>

    <template v-slot:footer>
      <v-btn
        width="33%"
        style="font-size:16px"
        class="back"
        dark
        @click="close()"
      >
        {{ $t('button.close') }}
      </v-btn>
      <v-btn
        width="33%"
        style="font-size:16px"
        class="next"
        dark
        @click="onClickCreate()"
      >
        {{ $t('button.add') }}
      </v-btn>
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { mapGetters, mapActions } from 'vuex';
import { splitMessageLine } from '@/lib/commonUtil';
import { ExtraFileListHeaders, ExtensionsAllowedInExtraPicture } from '@/lib/const';

export default {
  components: {
    Dialog,
    ErrorDialog,
  },
  props: {
    files: Array,
    onClickCreate: Function,
    onClickPreview: Function,
    onClickEdit: Function,
    onClickDelete: Function,
  },
  data() {
    return {
      // 編集フォームのスタイル指定
      conditionStyle: ['my-0', 'py-0', 'mx-4', 'px-4'],
      contractId: null,
      headers: ExtraFileListHeaders,

      // ローディング中であるかどうか
      isLoading: true,
    };
  },
  computed: {},
  methods: {
    ...mapGetters('user', ['userDetail']),
    ...mapActions('ui', ['setLoading']),
    open(contractId, agencyId) {
      this.contractId = contractId;
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    extensionCheck(fileName) {
      let extension = fileName.slice(
        ((fileName.lastIndexOf('.') - 1) >>> 0) + 2
      );
      return ExtensionsAllowedInExtraPicture.includes(extension)
    },
  },
  watch: {
    files() {
      this.$set(this, 'isLoading', false);
    },
  },
};
</script>
