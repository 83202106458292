var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: {
        title: _vm.title,
        width: "85%",
        "max-width": "85%",
        color: "next"
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  attrs: { width: "22%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("button.cancel")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "next",
                  attrs: { width: "22%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.onClickConfirmButton()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-container",
        { staticClass: "px-4 mx-4", staticStyle: { width: "auto" } },
        [
          _c(
            "ValidationObserver",
            { ref: "observer" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("v-row", { staticClass: "pl-4" }, [
                    _c(
                      "p",
                      {
                        staticClass: "pl-2 ma-0 font-weight-black",
                        staticStyle: { "font-size": "15px", height: "100%" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.templateList")) +
                            "\n          "
                        )
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "pl-2 ma-0",
                        staticStyle: { "font-size": "15px", height: "100%" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "description.messageContent.templateListText"
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ]),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-actions",
                        [
                          _vm._l(_vm.templateList.patternList, function(
                            template,
                            index
                          ) {
                            return _c(
                              "v-col",
                              { key: index, staticClass: "py-1" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "next",
                                      fab: "",
                                      "x-small": "",
                                      dark: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.preview(template, index)
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("mdi-magnify")])],
                                  1
                                ),
                                _c("strong", { staticClass: "pl-1" }, [
                                  _vm._v(_vm._s(_vm.replace(index)))
                                ])
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pt-4" },
                    [
                      _c(
                        "v-col",
                        [
                          [
                            _c("v-row", { staticClass: "pl-4" }, [
                              _c(
                                "p",
                                {
                                  staticClass: "pl-2 ma-0 font-weight-black",
                                  staticStyle: {
                                    "font-size": "15px",
                                    height: "100%"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("label.sendMessageBody")) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "pl-2 ma-0",
                                  staticStyle: {
                                    "font-size": "15px",
                                    height: "100%"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "description.messageContent.sendMessageBodyText"
                                        )
                                      ) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]),
                            _c("ValidationProvider", {
                              attrs: {
                                rules: _vm.messageRecord.rules,
                                immediate: ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("v-textarea", {
                                        ref: "textarea",
                                        attrs: {
                                          rows: "15",
                                          outlined: "",
                                          clearable: "",
                                          "clear-icon": "cancel",
                                          counter: _vm.messageRecord.counterMax
                                        },
                                        model: {
                                          value: _vm.messageRecord.value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.messageRecord,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "messageRecord.value"
                                        }
                                      }),
                                      _c("ValidationError", {
                                        staticClass: "ma-0",
                                        attrs: { error: errors[0] }
                                      })
                                    ]
                                  }
                                }
                              ])
                            })
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isSms
            ? [
                _c(
                  "v-card-text",
                  {
                    staticClass:
                      "font-weight-black text-right pr-2 pb-1 mx-auto"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "description.messageContent.sendMessageFooter.sms"
                          )
                        ) +
                        "\n      "
                    )
                  ]
                )
              ]
            : [
                _c(
                  "v-card-text",
                  {
                    staticClass:
                      "font-weight-black text-right pr-2 pb-1 mx-auto"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "description.messageContent.sendMessageFooter.mail"
                          )
                        ) +
                        "\n      "
                    )
                  ]
                )
              ],
          _c(
            "v-card-text",
            { staticClass: "text-right pt-0 pr-2 pb-1 mx-auto" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t(
                      "description.messageContent.sendMessageFooter.contractUrl"
                    )
                  ) +
                  "\n    "
              )
            ]
          ),
          !_vm.isSms
            ? [
                _c(
                  "v-card-text",
                  { staticClass: "text-right pt-0 pr-2 pb-1 mx-auto" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "description.messageContent.sendMessageFooter.modificationContent"
                          )
                        ) +
                        "\n      "
                    )
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c("ErrorDialog", { ref: "errorDialog" }),
      _c("SimpleDialog", {
        attrs: {
          maxWidth: 900,
          showDialog: _vm.showPreviewDialog,
          title: _vm.previewTitle,
          text: null,
          subText: null,
          multiLineText: _vm.previewMessage,
          negativeButtonTitle: _vm.$t("button.back"),
          positiveButtonTitle: _vm.$t("button.applyTemplate", {
            templateName: _vm.previewTitle
          }),
          onClickNegativeButton: _vm.closePreviewDialog,
          onClickPositiveButton: _vm.applyTemplate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }