var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: { title: _vm.$t("title.agency.fileList"), color: "next" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("button.close")))]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-layout",
            { attrs: { column: "", wrap: "" } },
            [
              _c("v-row", [
                _c("p", { staticClass: "py-4 px-3" }, [
                  _vm._v(_vm._s(_vm.$t("description.fileListDialog.text")))
                ])
              ]),
              _c("v-row", [
                _c("p", { staticClass: "py-1 px-3 font-weight-black" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("description.fileListDialog.autoImportTitle")
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c(
                "v-row",
                { staticClass: "mt-1", staticStyle: { height: "60px" } },
                [
                  _c("v-col", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.applicationForm")) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black float-right",
                          attrs: {
                            align: "center",
                            color: "next",
                            dark: _vm.isDownloadApplicationFormButtonEnabled,
                            disabled: !_vm.isDownloadApplicationFormButtonEnabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.onClickDownloadApplicationFormButton()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.downloadFile")))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-1", staticStyle: { height: "60px" } },
                [
                  _c("v-col", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.importantMatter")) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black float-right",
                          attrs: {
                            align: "center",
                            color: "next",
                            dark: _vm.isDownloadImportantMatterButtonEnabled,
                            disabled: !_vm.isDownloadImportantMatterButtonEnabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.onClickDownloadImportantMatterButton()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.downloadFile")))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.isDownloadInternetCovenantButtonEnabled
                ? [
                    _c(
                      "v-row",
                      { staticClass: "mt-1", staticStyle: { height: "60px" } },
                      [
                        _c("v-col", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("label.internetCovenant")) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "font-weight-black float-right",
                                attrs: {
                                  align: "center",
                                  color: "next",
                                  dark:
                                    _vm.isDownloadInternetCovenantButtonEnabled,
                                  disabled: !_vm.isDownloadInternetCovenantButtonEnabled
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onClickDownloadInternetCovenantButton()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("button.downloadFile")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.extraFileList && _vm.extraFileList.length > 0
                ? [
                    _c("v-row", [
                      _c("p", { staticClass: "pt-6 px-3 font-weight-black" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "description.fileListDialog.userUploadExtraTitle"
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ]),
                    _vm._l(_vm.extraFileList, function(extraFile) {
                      return _c(
                        "v-row",
                        { key: extraFile.id, staticClass: "mt-1" },
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              { staticStyle: { "word-break": "break-all" } },
                              [_vm._v(_vm._s(extraFile.name))]
                            )
                          ]),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-black float-right",
                                  attrs: {
                                    align: "center",
                                    color: "next",
                                    dark: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickDownloadExtraPictureButton(
                                        extraFile
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("button.downloadFile")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ]
                : _vm._e(),
              _vm.insuredUploads && _vm.insuredUploads.length > 0
                ? [
                    _c("v-row", [
                      _c("p", { staticClass: "pt-4 px-3 font-weight-black" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "description.fileListDialog.userUploadTitle"
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ]),
                    _vm._l(_vm.insuredUploads, function(insuredUpload) {
                      return _c(
                        "v-row",
                        { key: insuredUpload.id, staticClass: "py-1" },
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              { staticStyle: { "word-break": "break-all" } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(insuredUpload.fileName) +
                                    "\n            "
                                )
                              ]
                            )
                          ]),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-black float-right",
                                  attrs: {
                                    align: "center",
                                    color: "next",
                                    dark: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickDownloadInsuredUploadButton(
                                        insuredUpload
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("button.downloadFile")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }